import confetti from 'canvas-confetti';

// Wait for DOM to be fully loaded
document.addEventListener('DOMContentLoaded', () => {
  // Initialize all tooltips (using native browser tooltips)
  const tooltipElements = document.querySelectorAll('[data-toggle="tooltip"]');
  tooltipElements.forEach(element => {
    element.setAttribute('title', element.getAttribute('data-original-title') || element.getAttribute('title'));
  });

  setInitialAffirmation();
  setUnicornAffirmationOnClick();
  
  // Whimsy injection handler
  const whimsyButton = document.querySelector('.whimsy-injection');
  if (whimsyButton) {
    whimsyButton.addEventListener('click', () => {
      // Easter egg message
      console.log("Hi. 👋 If you're reading this we should chat. red.bell4711@fastmail.com");

      // Confetti animation
      createConfettiEffect();
    });
  }
});

// Separate confetti logic for better organization
const createConfettiEffect = () => {
  const DURATION = 15 * 1000; // 15 seconds
  const INTERVAL = 200; // Time between bursts
  const end = Date.now() + DURATION;

  const interval = setInterval(() => {
    if (Date.now() > end) {
      clearInterval(interval);
      return;
    }

    confetti({
      startVelocity: 30,
      spread: 360,
      ticks: 60,
      shapes: ['square'],
      origin: {
        x: Math.random(),
        y: Math.random() - 0.2 // Start slightly higher for better fall effect
      }
    });
  }, INTERVAL);
};

// enable popovers
var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
  return new bootstrap.Popover(popoverTriggerEl)
})

// return a random affirmation
const getRandomAffirmation = () => {
  const affirmations = [
    "You're a walking ray of sunshine! ☀️",
    "Your smile could power a small city! ✨",
    "You make the world 127% more awesome!",
    "You're cooler than a penguin in sunglasses! 🐧",
    "You're totally rocking this whole existence thing!",
    "High five for being uniquely you! 🖐️",
    "You're like a unicorn, but even more magical! 🦄",
    "You've got more potential than a coffee-powered rocket! ☕",
    "You're better than sliced bread (and that's saying something)!",
    "You're crushing it like a grape in a smoothie! 🍇"
  ];

  return affirmations[Math.floor(Math.random() * affirmations.length)];
}

// set initial affirmation
const setInitialAffirmation = () => {
  const unicorn = document.getElementById('unicorn');
  unicorn.setAttribute('data-bs-content', getRandomAffirmation());
}

// display a random affirmation on click
const setUnicornAffirmationOnClick = () => {
  const unicorn = document.getElementById('unicorn');

  // change the affirmation on click  
  unicorn.addEventListener('click', function() {
    unicorn.setAttribute('data-bs-content', getRandomAffirmation());
  })
}

